import _ from 'lodash';
import * as exceptionCode from '@/constants/exceptionsCode';

export default (apiMetadata) => {
  const code = parseInt(_.get(apiMetadata, 'data.responseCode') || 500, 100);
  const detailErrorCode = _.get(apiMetadata, 'data.responseBody.metadata.data.code');
  if (detailErrorCode && detailErrorCode > 40000) {
    return errorSet[detailErrorCode] ? errorSet[detailErrorCode] : null;
  }
  return errorSet[code] ? errorSet[code] : null;
};

const errorSet = {
  [exceptionCode.OVERAL_ERROR]: 'api.error.bad_input',
  [exceptionCode.UNAUTHORIZED]: 'api.error.unauthorized',
  [exceptionCode.UNREGISTERED_DEVICE]: 'api.error.unregistered_device',
  [exceptionCode.INTERNAL_SERVER_ERROR]: 'api.error.internal_error',

  [exceptionCode.STAY_NOT_FOUND]: 'api.error.stay_not_found',
  [exceptionCode.ROOM_NOT_FOUND]: 'api.error.stay_without_room',
  [exceptionCode.PROPERTY_NOT_FOUND]: 'api.error.property_not_found',
  [exceptionCode.ROOM_DIRECTIONS_NOT_FOUND]: 'api.error.could_not_send',
  [exceptionCode.RESERVATION_WITHOUT_ROOMS]: 'api.error.stay_without_room',
  [exceptionCode.RESERVATION_WITHOUT_CHECK_INS]: 'api.error.stay_without_checkins',
  [exceptionCode.STAY_DEVICE_MISMATCH]: 'api.error.unauthorized',
  [exceptionCode.STAY_ROOM_MISMATCH]: 'api.error.unauthorized',
  [exceptionCode.CARD_LIMIT_REACHED]: 'api.error.card_limit_reached',
  [exceptionCode.CARD_SPARE_LIMIT_REACHED]: 'api.error.card_limit_reached',
  [exceptionCode.LANGUAGE_INVALID]: 'api.error.internal_error',
  [exceptionCode.LANGUAGE_NOT_FOUND]: 'api.error.internal_error',
  [exceptionCode.CONTACT_INVALID_NUMBER]: 'api.error.invalid_contact_number',
  [exceptionCode.ACCESS_TOKEN_MISMATCH]: 'api.error.unauthorized',
  [exceptionCode.DATE_TIMESTAMP_MISMATCH]: 'api.error.unauthorized',
  [exceptionCode.INVALID_LOGIN_CREDENTIALS_USERNAME]: 'api.error.unauthorized',
  [exceptionCode.INVALID_LOGIN_CREDENTIALS_PASSWORD]: 'api.error.unauthorized',
  [exceptionCode.INVALID_LOGIN_CREDENTIALS_PROPERTY]: 'api.error.unauthorized',
  [exceptionCode.HEADER_MISSING_DEVICE]: 'api.error.unauthorized',
  [exceptionCode.HEADER_MISSING_DATE_TIMESTAMP]: 'api.error.unauthorized',
  [exceptionCode.HEADER_MISSING_ACCESS_TOKEN]: 'api.error.unauthorized',
  [exceptionCode.HEADER_MISSING_VERSION]: 'api.error.unauthorized',
  [exceptionCode.HEADER_WRONG_CONTENT_TYPE]: 'api.error.unauthorized',
  [exceptionCode.DATA_MISSING]: 'api.error.bad_input',
  [exceptionCode.DATA_RESERVATION_ID_MISSING]: 'api.error.bad_input',
  [exceptionCode.DATA_TYPE_MISSING]: 'api.error.bad_input',
  [exceptionCode.DATA_ROOM_MISSING]: 'api.error.bad_input',
  [exceptionCode.DATA_LOGIN_MISSING]: 'api.error.unauthorized',
  [exceptionCode.DATA_CONTACT_MISSING]: 'api.error.bad_input',
  [exceptionCode.REQUEST_ID_MISSING]: 'api.error.bad_input',
  [exceptionCode.ROOM_MISSING_IN_PMS]: 'api.error.stay_without_room',
  [exceptionCode.DATE_STAY_NOT_ACTIVE]: 'api.error.date_not_active',
  [exceptionCode.DATE_STAY_EXPIRED]: 'api.error.date_expired',
  [exceptionCode.NO_AVAILABLE_ROOM_TO_ASSING]: 'api.error.no_rooms', // There are no available room to assign - contact reception
  [exceptionCode.ROOM_NOT_CLEAN]: 'api.error.no_rooms', // Rooms are all dirty - cannot assign new room, come back later
  [exceptionCode.CHECKIN_VALIDATE_ERROR]: 'api.error.checkin_invalid', //  Checkin validate returned an error
  [exceptionCode.CHECKOUT_FAILED_UNPAID_BILLS]: 'api.error.updaid', // Checkout failed because there are unpaid bills on reservation
  [exceptionCode.ERROR_EARLY_CHECKOUT]: 'api.error.early_checkout', // Cannot make early checkout
  [exceptionCode.ERROR_CHECKED_OUT_ALREADY]: 'api.error.checked_out_already', // Booking has been checked out already
  [exceptionCode.ERROR_CHECKOUT]: 'api.error.checkout_general', // Checkout error - contact reception
};
