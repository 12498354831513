<template>
  <div class="checkin">
    <LanguageSelect absolutePosition />

    <ProfileDetail />

    <h1>{{ $t('checkin.list.your_stay') }}</h1>

    <div class="checkin-infolist">
      <div class="checkin-info-block">
        <h3 v-if="reservationIsUnpaid" class="checkin-list-title">{{ $t('checkin.list.total_to_pay') }}</h3>
        <strong v-if="reservationIsUnpaid" class="price-to-pay">
          152 000,00 Kč
        </strong>

        <h3 class="checkin-list-title checkin-list-title-wide">{{ $t('checkin.list.more_information') }}</h3>
        <p class="reservation-info-block">
          <span v-if="rooms && rooms.length > 0">
            {{ $t('checkin.list.rooms_count_and_type') }}
            <span v-for="(room, index) in rooms" :key="room.id">
              1x {{ roomType(index) }} {{ index === rooms.length - 1 ? '' : ', ' }}
            </span>
          </span>
        </p>
      </div>
      <div class="checkin-guests-block">
        <h3 class="checkin-list-title">{{ $t('checkin.list.checkin_cards') }}</h3>

        <div class="guest-list">
          <div v-for="checkIn in checkIns"
              :key="checkIn.id"
              class="guest-list-item"
          >
            <div class="guest-list-icon guest-list-icon--sent"></div>
            <strong class="guest-list-name">{{ checkIn.first_name }} {{ checkIn.last_name }}</strong>
          </div>
          <div v-for="(item, index) in emptyGuestProfiles"
              :key="index"
              @click="addCheckIn"
              class="guest-list-item">
            <div class="guest-list-icon guest-list-icon--missing"></div>
            <strong class="guest-list-name">{{ $t('checkin.list.empty_guest_label') }} {{ index + 1 + (checkIns.length || 0) }}</strong>
          </div>
        </div>

        <ButtonInput v-if="canMakeCheckIn"
            type="button"
            :disabled="loading"
            :loading="loadingCheckIn"
            @click="addCheckIn"
        >
          {{ $t('checkin.list.make_checkin') }}
        </ButtonInput>
      </div>

      <div class="button-buttons">
        <div v-if="reservationIsUnpaid" class="checkin-info-block">
          <ButtonInput type="button"
            :disabled="loading"
            @click="handlePay"
          >
            {{ $t('checkin.list.pay_amount') }} 152 000,00 Kč
          </ButtonInput>
        </div>
        <div class="checkin-guests-block">
          <span v-if="reservationIsUnpaid" class="info-span">
            {{ $t('checkin.list.pay_for_reservation') }}
          </span>
          <span v-else-if="canMakeCheckIn" class="info-span">
            {{ $t('checkin.list.make_checkin_first') }}
          </span>
          <span v-else-if="rooms.length === 0" class="info-span">
            {{ $t('checkin.list.cant_get_card_room_not_ready') }}
          </span>
          <ButtonInput type="button"
            :disabled="canMakeCheckIn || reservationIsUnpaid || rooms.length === 0 || loading"
            :loading="loadingRoomCards"
            @click="getRoomCard"
          >
            {{ $t('checkin.list.get_the_room_card') }}
          </ButtonInput>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import LanguageSelect from '@/components/LanguageSelect/index';
import { GET_PROPERTY_DETAIL } from '@/store/property';
import {
  GET_RESERVATION_DETAIL,
  GET_CHECKINS,
  GET_ROOMS,
  UPDATE_RESERVATION,
} from '@/store/reservation';
import * as endpoints from '@/api/handler.endpoints';
import * as methods from '@/api/handler.methods';
import api from '@/api/handler';
import errorHandler from '@/api/errorHandler';
import ProfileDetail from '@/components/ProfileDetail';
import ButtonInput from '@/components/Form/Input/ButtonInput';
import { logEvent } from '@/helpers/analytics';
import * as exceptionCode from '@/constants/exceptionsCode';
import flashMessage from '@/helpers/flashMessage';
import { getTranslated } from '@/helpers/translations';

export default {
  name: 'CheckInList',
  async mounted() {
    if (!this.reservation) {
      await this.$router.push('/home');
    }
  },
  components: {
    LanguageSelect,
    ProfileDetail,
    ButtonInput,
  },
  data() {
    return {
      loading: false,
      loadingCheckIn: false,
      loadingRoomCards: false,
      currentStep: null,
    };
  },
  computed: {
    ...mapGetters({
      property: GET_PROPERTY_DETAIL,
      reservation: GET_RESERVATION_DETAIL,
      checkIns: GET_CHECKINS,
      rooms: GET_ROOMS,
    }),
    reservationIsUnpaid() {
      return false;
    },
    emptyGuestProfiles() {
      return this.reservation.total_guests_count - this.checkIns.length || 0;
    },
    canMakeCheckIn() {
      return this.reservation.total_guests_count > this.checkIns.length;
    },

  },
  methods: {
    addCheckIn() {
      if (!this.canMakeCheckIn) {
        return;
      }
      this.loading = true;
      this.loadingCheckIn = true;

      logEvent('onCheckInListAddCheckInClick');
      this.$router.push({ name: 'checkin-add' });
    },
    handlePay() {
      // @TODO...
      logEvent('onCheckInListPayButtonClick');
      console.log('handlePay');
    },
    roomType(index) {
      if (getTranslated(_.get(this.rooms[index], 'room_type'), this.$i18n.locale)) {
        return getTranslated(_.get(this.rooms[index], 'room_type'), this.$i18n.locale);
      }
      return this.rooms[index].room_type.en_GB;
    },
    getRoomCard() {
      if (this.canMakeCheckIn || this.reservationIsUnpaid || this.rooms.length === 0) {
        return;
      }

      this.loading = true;
      this.loadingRoomCards = true;

      this.sending = true;
      logEvent('onCheckInListIssueCardClick');

      api(this, endpoints.ENDPOINT_RESERVATION, methods.GET_RESERVATION)(this.reservation.pin)
        .then(({data}) => {
          return this.$store.dispatch(UPDATE_RESERVATION, data)
            .then(() => {
              this.$router.push('/issue-card');
            });
        })
        .catch((err) => {
          this.sending = false;
          this.handleError(err);
        });
    },
    handleError(err) {
      const errorCode = _.get(err, 'response.data.responseCode') || 500;
      let message = errorHandler(err.response);
      if (errorCode === 400) {
        const data = _.get(err, 'response.data.responseBody.metadata.data');
        const code = _.get(data, 'code');

        if ([
          exceptionCode.ROOM_NOT_FOUND,
          exceptionCode.STAY_ROOM_MISMATCH,
          exceptionCode.DATA_ROOM_MISSING,
          exceptionCode.ROOM_MISSING_IN_PMS,
          exceptionCode.NO_AVAILABLE_ROOM_TO_ASSING,
          exceptionCode.ROOM_NOT_CLEAN,
          exceptionCode.RESERVATION_WITHOUT_ROOMS,
        ].indexOf(code) >= 0) {
          return this.$store.dispatch(UPDATE_RESERVATION, {reservation: data})
            .then(() => {
              this.$router.push({
                name: 'no-rooms-reservation',
              });
            })
            .catch(() => {
              this.sending = false;
            });
        }
      }

      if (!message) {
        message = this.$t('api.error.general_error');
      }
      flashMessage(this.$store, this.$t(message), 'danger');
    },
  },
};
</script>

<style lang="scss">
  .checkin {
    text-align: center;
    width: calc(100% - 4rem);
    max-width: 50rem;
  }

  .checkin-infolist {
    display: flex;
    flex-wrap: wrap;
  }

  .checkin-info-block {
    width: 50%;
    flex: 0 1 50%;
  }

  .checkin-guests-block {
    width: 50%;
    flex: 0 1 50%;
  }

  .button-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .price-to-pay {
    font-size: 2rem;
  }

  h3 {
    margin-bottom: .5rem;
  }

  .guest-list {
    max-width: 14rem;
    margin: 1rem auto;

    .guest-list-item {
      display: flex;
      align-items: center;
      margin-bottom: .5rem;
    }

    .guest-list-name {
      flex: 1;
      font-weight: 400;
      text-align: left;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .guest-list-icon {
      width: 1rem;
      height: 1rem;
      margin-right: 1rem;
      border-radius: 50%;
      background: #000;
      border: 2px solid transparent;

      &--missing {
        background: transparent;
        border-color: $red-color;
      }

      &--sent {
        background: transparent;
        border-color: $green-color;

        &:before {
          content: '✓';
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: .6rem;
          padding-left: .32rem;
          color: $green-color;
        }
      }
    }
  }

  .checkin-list-title {
    max-width: 14rem;
    margin-left: auto;
    margin-right: auto;
    text-align: left;

    &.checkin-list-title-wide {
      max-width: 20rem;
    }
  }

  .reservation-info-block {
    max-width: 20rem;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
</style>