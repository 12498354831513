export const OVERAL_ERROR = 400; // Overal error
export const UNAUTHORIZED = 401; // Unauthorized
export const INTERNAL_SERVER_ERROR = 500; // broken

export const STAY_NOT_FOUND = 40410; // Stay not found
export const ROOM_NOT_FOUND = 40420; // Room not found
export const ROOM_DIRECTIONS_NOT_FOUND = 40430; // Room does not have directions filled in, or does not have directions in provided language
export const PROPERTY_NOT_FOUND = 40440; // Property not found
export const RESERVATION_WITHOUT_ROOMS = 40010; // Reservation does not have any rooms attached in room_occupy_date
export const RESERVATION_WITHOUT_CHECK_INS = 40011; // Reservation does not have any check ins done
export const STAY_DEVICE_MISMATCH = 40020; // Stay is connected to different property than device is.
export const STAY_ROOM_MISMATCH = 40021; // Room is connected to different stay than was provided
export const CARD_LIMIT_REACHED = 40050; // Cannot dispense another card, card limit is reached
export const CARD_SPARE_LIMIT_REACHED = 40051; // Cannot dispense another spare card, spare card limit reached
export const LANGUAGE_INVALID = 40060; // Language is invalid
export const LANGUAGE_NOT_FOUND = 40461; // Language header not found
export const CONTACT_INVALID_NUMBER = 40070; // Contact number is invalid
export const UNREGISTERED_DEVICE = 40310; // Device is not registered yet. There  is a need to use login endpoint first
export const ACCESS_TOKEN_MISMATCH = 40110; // Access token does not match checksum
export const DATE_TIMESTAMP_MISMATCH = 40111; // Date timestamp does not match checksum
export const INVALID_LOGIN_CREDENTIALS_USERNAME = 40120; // Username is wrong
export const INVALID_LOGIN_CREDENTIALS_PASSWORD = 40121; // Password is wrong
export const INVALID_LOGIN_CREDENTIALS_PROPERTY = 40122; // User is not connected with  provided property
export const HEADER_MISSING_DEVICE = 40080; // Device header is missing or empty
export const HEADER_MISSING_DATE_TIMESTAMP = 40081; // Date-timestamp header is missing or empty
export const HEADER_MISSING_ACCESS_TOKEN = 40082; // Access-token header is missing or empty
export const HEADER_MISSING_VERSION = 40083; // Version header is missing or empty
export const HEADER_WRONG_CONTENT_TYPE = 40090; // Content-type header is invalid
export const DATA_MISSING = 40090; // Request does not contain any data
export const DATA_RESERVATION_ID_MISSING = 40091; // Reservation id attribute is missing in data
export const DATA_TYPE_MISSING = 40092; // Type is missing in data
export const DATA_ROOM_MISSING = 40093; // Room id is missing in data
export const DATA_LOGIN_MISSING = 40094; // Login data are missing
export const DATA_CONTACT_MISSING = 40095; // Contact data is missing
export const REQUEST_ID_MISSING = 400100; // ID is missing in request
export const ROOM_MISSING_IN_PMS = 400200; // Room is missing in PMS and could not be paired - card dispense
export const DATE_STAY_NOT_ACTIVE = 400300; // Cannot return Stay - checkin is in future
export const DATE_STAY_EXPIRED = 400301; // Cannot return Stay - checkout date is in past
export const NO_AVAILABLE_ROOM_TO_ASSING = 40140; // There are no available room to assign - contact reception
export const ROOM_NOT_CLEAN = 40141; // Rooms are all dirty - cannot assign new room, come back later
export const CHECKIN_VALIDATE_ERROR = 400401; //  Checkin validate returned an error
export const CHECKOUT_FAILED_UNPAID_BILLS = 400500; // Checkout failed because there are unpaid bills on reservation
export const ERROR_EARLY_CHECKOUT = 40003; // Cannot make early checkout
export const ERROR_CHECKED_OUT_ALREADY = 40004; // Booking has been checked out already
export const ERROR_CHECKOUT = 400500; // Checkout error - contact reception

export const CARD_SPARE_BEFORE_PROPER = 40052; // Cannot dispense spare card before proper card
export const CHECKIN_MISSING_GUEST = 30001; // One or more guest profiles is missing, number of guest must be greater or equal to PAX
export const CHECKIN_MISSING_ROOM = 30002; // Physical room hasn't been assigned to booking yet, contact front-office
export const CHECKIN_ROOM_NOT_READY = 30003; // Room hasn't been cleaned up, inspected yet
export const CHECKIN_ROOM_OCCUPIED = 30004; // Previous booking hasn't been checked-out yet
export const CHECKIN_GUEST_PROFILE_INCOMPLETE = 30005; // Some of the data are missing in the guest profile, contact front-office for more details
export const CHECKIN_PRE_CHECKIN_PAYMENT_REQUIRED = 30006; // Check-in procedure cannot be completed until pre-payment of booking is registered in system
export const CHECKIN_ARRIVAL_DATE = 30007; // Checkin can be made on arrival date only
export const KIOSK_DISABLED = 40320; // hotel does not have kiosk enabled or its settings are missing
