<template>
  <div class="profile-detail">
    <div class="profile-detail__icon">
      <UserSvg />
    </div>
    <div class="profile-detail__info">
      <strong>
        {{ reservationDetail.first_name }}{{ !anonymize ? ` ${reservationDetail.last_name}, ID: ${reservationDetail.pin}` : ''}}
      </strong>
      <span class="stay-data">
        <span>{{ dateFrom }}</span>
        <StayArrowSvg />
        <span>{{ dateTo }}</span>
      </span>
    </div>
    <div class="floating-button-exit" @click="clearReservationStoreAndRedirect()">
      <LogoutSvg />
      <span>{{ $t('action.button.exit') }}</span>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import LogoutSvg from '@/assets/images/icons/logout.svg';
import {
  UPDATE_RESERVATION,
  GET_RESERVATION_DETAIL,
} from '@/store/reservation';
import StayArrowSvg from '@/assets/images/icons/stayArrow.svg';
import UserSvg from '@/assets/images/icons/user.svg';

export default {
  name: 'ProfileDetail',
  components: {
    LogoutSvg,
    StayArrowSvg,
    UserSvg,
  },
  props: {
    anonymize: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    reservationDetail() {
      return this.$store.getters[GET_RESERVATION_DETAIL] || [];
    },
    dateFrom() {
      return (new moment(this.reservationDetail.date_from)).format('D. M. YYYY');
    },
    dateTo() {
      return (new moment(this.reservationDetail.date_to)).format('D. M. YYYY');
    },
  },
  methods: {
    clearReservationStoreAndRedirect() {
      return this.$store.dispatch(UPDATE_RESERVATION, {})
        .then(() => {
          this.$router.push({name: 'home'});
        })
        .catch(() => {
          this.$router.push({name: 'home'});
        });
    },
  },
};
</script>

<style lang="scss">
  .profile-detail {
    display: flex;
    align-items: center;
    position: fixed;
    text-align: left;
    top: 1rem;
    left: 1rem;
    font-size: .875rem;
    line-height: 1.125rem;
    z-index: 2;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 2rem;
        height: 2rem;
        margin-right: 0.5rem;
      }
    }

    &__info {
      opacity: .7;

      strong {
        display: block;
      }

      .stay-data {
        display: flex;
        align-items: center;

        svg {
          margin: 0 .5rem;
        }
      }
    }
  }

  .floating-button-exit {
    color: $white-color;
    font-size: .875rem;
    display: inline-flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background: $red-color;
    margin-left: 1rem;
    cursor: pointer;
    z-index: 2;
    @include border-radius(.75rem);

    svg {
      width: 1rem;
      height: 1rem;
      margin-right: .5rem;
    }
  }
</style>
